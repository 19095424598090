/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";

type DataProps = {
  label: string;
  current: string;
  onSelect: (label: string) => void;
};

const NewsCategoryItem = ({
  label,
  current,
  onSelect,
}: DataProps): JSX.Element => {
  if (label.toLowerCase() === current) {
    return (
      <a
        css={css`
          font-weight: bold;
          text-decoration: underline;
        `}
      >
        {label}
      </a>
    );
  } else {
    return (
      <a
        css={css`
          font-weight: bold;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        `}
        onClick={() => onSelect(label)}
      >
        {label}
      </a>
    );
  }
};

export default NewsCategoryItem;
